.custom {
  $size-box: 150px;

  &-file-input {

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .no-image {
        border: 1px dashed $primary;
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        width: $size-box;
        height: $size-box;

        svg {
          position: absolute;
          top: calc(($size-box / 2) - 15px);
          left: calc(($size-box / 2) - 15px);
        }
      }

      .profile {
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        width: $size-box;
        height: $size-box;

        &:hover {
          span {
            opacity: 1;
          }

          img {
            opacity: 0.4;
          }
        }

        span {
          transition: opacity 0.15s ease-in-out;
          position: absolute;
          color: $primary;
          opacity: 0;
        }

        img {
          transition: opacity 0.15s ease-in-out;
          height: $size-box;
          border-radius: 50%;
        }
      }
    }
  }
}
// Theme colors
// see more: https://getbootstrap.com/docs/5.2/customize/color/

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$gray: #19191D;

$red: #e61f26;
$green: #198754;
$tertiary: #15151A;
$border-color: #343434;
$primary-gray: #101828;
$secondary-bg: #19191D;
$color-facebook: #2948EC;
$color-youtube: #E61F26;
$color-instagram: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
$color-tiktok: #000000;
$placeholder-color: #636771;

$primary: $red;
$secondary: $white;
$success: $green;
$danger: $red;
$light: $white;
$dark: $black;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'tertiary' : $tertiary,
);

// Form controls
// see more: https://getbootstrap.com/docs/5.2/forms/form-control/#sass

$input-bg: $tertiary;
$input-color: $light;
$input-border-color: $border-color;
$input-placeholder-color: $placeholder-color;
$input-focus-box-shadow: 0;
$input-group-addon-bg: $tertiary;
$input-group-addon-border-color: $border-color;

// Checks and radios
// see more: https://getbootstrap.com/docs/5.2/forms/checks-radios/#variables

$form-check-input-border: 1px solid $white;

// Validation
// see more: https://getbootstrap.com/docs/5.2/forms/validation/#map

$form-validation-states: (
  'valid': ('color': $success, 'icon': unset),
  'invalid': ('color': $danger, 'icon': unset),
);

// Cards
// see more: https://getbootstrap.com/docs/5.2/components/card/#sass-variables
$card-border-width: 0;
$card-bg: $gray;

$pagination-margin-start: 2;

// Table
// https://getbootstrap.com/docs/5.3/content/tables/#variables
$table-color: $white;
$table-bg: $tertiary;
.multi-select {
  .multi-select {
    &.is-invalid {

      .css-uy09r2-control,
      .css-g7qhrg-control {
        border: 1px solid $primary;
      }
    }
  }
}
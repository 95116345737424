@mixin box-text() {
  width: 500px;
  height: 100%;
  white-space: nowrap;
  display: block;
  position: relative;
  z-index: -1;
  top: 35rem;
  opacity: .8;
}

@mixin text() {
  font-size: 15rem;
  position: absolute;
  height: 65px;
  line-height: 32px;
}

.text-logo-left {
  @include box-text;
  left: 0px;

  .text {
    position: relative;

    &::after {
      @include text;
      content: attr(data-bg-text);
      top: -344px;
      left: -194px;
      transform-origin: top left;
      transform: rotate(90deg) translateX(10%);
      color: rgba($light, .1);
    }
  }

  .text-in {
    position: relative;

    &::after {
      @include text;
      content: attr(data-bg-text);
      top: 0;
      left: 220px;
      transform-origin: top left;
      transform: rotate(90deg) translateX(10%);
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      color: rgba($primary, .4);
    }
  }
}

.text-logo-right {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  display: grid;
  position: relative;
  z-index: -1;
  top: 29rem;
  right: 0;
  opacity: .8;

  .text {
    position: relative;

    &::after {
      @include text;
      content: attr(data-bg-text);
      top: 502px;
      right: 8px;
      transform-origin: top right;
      transform: rotate(270deg) translateX(10%);
      color: rgba($light, .1);
    }
  }

  .text-in {
    position: relative;

    &::after {
      @include text;
      content: attr(data-bg-text);
      top: 400px;
      right: 171px;
      transform-origin: top right;
      transform: rotate(270deg) translateX(10%);
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      color: rgba($primary, .4);
    }
  }
}

.box-logo-text {
  position: relative;
  width: 100%;
  height: 100%;
  top: 30rem;
  left: 0;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
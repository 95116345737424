.card-container {
  position: relative;

  .card-register{

    .reward-title {
      hr {
        height: 2px;
        background: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(0, 0, 0, 0)), color-stop(0.5, #333333), to(rgba(0, 0, 0, 0)));
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
        background: -moz-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
        background: -o-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
        background: linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
        border: 0;
      }
      hr:after {
        display: block;
        content: '';
        height: 30px;
        background-image: -webkit-gradient(radial, 50% 0%, 0, 50% 0%, 116, color-stop(0%, #e61f26), color-stop(100%, rgba(255, 255, 255, 0)));
        background-image: -webkit-radial-gradient(center top, farthest-side, #e61f26 0%, rgba(255, 255, 255, 0) 100%);
        background-image: -moz-radial-gradient(center top, farthest-side, #e61f26 0%, rgba(255, 255, 255, 0) 100%);
        background-image: -o-radial-gradient(center top, farthest-side, #e61f26 0%, rgba(255, 255, 255, 0) 100%);
        background-image: radial-gradient(farthest-side at center top, #e61f26 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
}

.text-normal {
  font-size: 12px;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background: rgb(255, 37, 37);
  width: 100%;
  height: 0;
  transition:opacity 0s ease;
  opacity: 0;
}

.card-container:hover .overlay{
  bottom: 0;
  height: 100%;
  opacity:1;
  cursor: pointer;
}

.text {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.player-list {
  width: 45px;
  height: 45px;
}
.custom-navbar {
  background-color: $dark;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 1rem 0;

  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;

    a {
      font-size: 24px;
    }
  }

  @include media-breakpoint-down(sm) {

    .action>.button,
    .switch-language {
      width: 50%;
    }
  }

  .button.profile {
    .dropdown-toggle {
      padding: 0;
      height: 38px;
      display: flex;
      align-items: center;
      color: $white;
      border: unset;

      &:active {
        border: unset;
        background-color: transparent;
      }

      &::after {
        content: unset;
      }

      .image {
        height: 38px;
        width: 38px;
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        border-radius: 50%;

        img {
          height: 38px;
          width: 38px;
          border-radius: 50%;
        }
      }

      .no-image {
        height: 38px;
        width: 38px;
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        border: 1px solid $white;
      }

      .info {
        margin-left: .5rem;

        .name {
          font-size: 16px;
        }

        .role {
          font-size: 12px;
          color: $primary;
        }
      }

      .dropdown-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }

    .dropdown-menu {
      background-color: $dark;

      .dropdown-item {
        &:hover {
          background-color: unset;
        }

        button {
          text-align: left;
        }
      }
    }
  }

  .switch-language {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;

    button {
      border-radius: 0;
      padding-left: 5px;
      padding-right: 5px;
      border-left: 0;
      border-right: 0;
      border-bottom: 3px solid $dark;

      &.current {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          background: radial-gradient(circle, $primary 0%, $black 100%);
          height: 3px;
          box-shadow: rgb(230 31 38 / 20%) 0px -2px 7px 4px;
        }
      }
    }
  }

  .navbar-toggler,
  .navbar-toggler-icon {
    color: $white;

    &:focus {
      box-shadow: unset;
    }
  }
}
.badge-status {
  padding: 8px 24px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 400;

  &.bg-warning {
    background-color: rgba($warning , .2) !important;

    svg {
      color: $warning;
    }
  }

  &.bg-success {
    background-color: rgba($success , .2) !important;

    svg {
      color: $success;
    }
  }

  &.bg-primary {
    background-color: rgba($primary , .2) !important;

    svg {
      color: $primary;
    }
  }

  &.bg-info {
    background-color: rgba($info , .2) !important;

    svg {
      color: $info;
    }
  }

  &.bg-danger {
    background-color: rgba($danger , .2) !important;

    svg {
      color: $danger;
    }
  }
}
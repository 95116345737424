.background {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60dvh;
  position: absolute;
  width: 100%;
  z-index: -1;
  left: 0;
  top: 60px;
}
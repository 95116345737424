.date-picker {
  .date-picker-invalid-feedback {
    .message {
      display: none;
    }

    .message-is-showed {
      display: block;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 0.875em;
      color: #e61f26;
    }
  }

  .react-datepicker-popper {
    z-index: 5;
  }

  .react-datepicker {
    border: unset;

    .react-datepicker__navigation {
      span {
        position: relative;
        top: 5px;
      }
    }

    .react-datepicker__header {
      background-color: $gray;
      border-bottom: unset;

      div {
        color: $white;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background-color: $primary;
    }
  }
}
.reward-money {
  width: 700px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .text-number {
    font-size: 3rem;

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }

  .img-coin {
    width: 65px;
    height: auto;
    margin-right: 0.7rem;

    @include media-breakpoint-down(md) {
      margin-right: 0;
      width: 35px;
    }
  }

  .polygon-up {
    width: 100%;
    height: 50px;
    aspect-ratio: 1;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
    opacity: .5;
    background: rgb(240, 240, 240);
    background: linear-gradient(0deg, rgba(240, 240, 240, 0) -20%, rgb(217, 217, 217) 162%);

    @include media-breakpoint-down(md) {
      width: 80%;
      height: 30px;
    }
  }

  .polygon-down {
    position: relative;
    margin-top: -30px;
    width: 100%;
    height: 100px;
    aspect-ratio: 1;
    clip-path: polygon(0 50%, 50% 100%, 100% 50%);
    opacity: 1;
    background: rgb(240, 240, 240);
    background: linear-gradient(180deg, rgba(240, 240, 240, 0) 40%, rgb(164 163 163) 117%);

    @include media-breakpoint-down(md) {
      width: 80%;
      height: 80px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: -10px;
      width: 100%;
      height: 100px;
      aspect-ratio: 1;
      clip-path: polygon(0 50%, 50% 100%, 100% 50%);
      opacity: 1;
      background: rgb(240, 240, 240);
      background: linear-gradient(180deg, rgb(0 0 0) 40%, rgb(76 75 75) 117%);
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: -3px;
      width: 100%;
      height: 100px;
      aspect-ratio: 1;
      clip-path: polygon(0 50%, 50% 100%, 100% 50%);
      opacity: 1;
      background: #000;
    }
  }
}


.box-detail-reward {
  max-width: 800px;
  text-align: center;
  margin: auto;
  padding-top: 5rem;
  padding-bottom: 2rem;
  width: 100%;
  border: 1px solid rgba($primary, 0.4);
  background-color: rgba($gray-800, 0.2);
  position: relative;

  .img-profile {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid $primary;
    object-fit: cover;
  }

  .list-reward {
    margin-top: 2rem;
    font-size: 1.4rem;

    @include media-breakpoint-down(md) {
      font-size: .8rem;
    }

    .col {
      padding-left: 2rem;
    }

    .row {
      &:first-child {
        font-size: 1.8rem;

        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }

        .col {
          &:last-child {
            background: $gray-800;
            background: linear-gradient(263deg, rgba($gray-800, 0) 20%, rgba(#ff070773, 1) 100%);
          }
        }
      }
    }
  }
}
.custom-info-card {
  cursor: pointer;
  border: 1px solid $gray;
  transition: border 0.15s ease-in-out;

  &:hover {
    border: 1px solid $primary;
    transition: border 0.15s ease-in-out;
  }

  .header {
    .img {
      width: 100px;
      height: 100px;

      img {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      svg {
        font-size: 50px;
      }
    }
  }

  .body {
    .border-top {
      border-top: 1px solid $border-color;
    }

    .col {
      &:nth-child(n+2) {
        border-left: 1px solid $border-color;
      }
    }

    .nickname {
      height: 27px;
    }

    .nationality {
      text-align: center;
      padding-top: .5rem;
      padding-bottom: .5rem;
      font-size: 14px;
      height: 37px;

      .flag {
        margin-right: .25rem;
      }
    }

    .club {
      text-align: center;
      padding-top: .5rem;
      padding-bottom: .5rem;
      font-size: 14px;

      img {
        height: 18px;
        margin-right: .25rem;
      }
    }
  }

  .footer {
    border-top: 1px solid $border-color;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
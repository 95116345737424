$sizes: "5" 5%,
  "10" 10%,
  "20" 20%,
  "30" 30%,
  "40" 40%,
  "60" 60%,
  "70" 70%,
  "80" 80%,
  "90" 90%;

@each $size-name,
$size in $sizes {
  .w-#{$size-name} {
    width: $size;
  }
}
.custom-nav {
  .nav-item {
    .nav-link {
      border: 2px solid rgba($border-color, .5);
      width: 200px;
      color: $white;

      &.active {
        border: 2px solid $primary;
        background-color: unset;
      }
    }
  }
}

.custom-tab {
  &.nav-tabs {
    border-bottom: none;
  }

  .nav-item {
    .nav-link {
      color: $white;
      border: 0;
      border-color: unset;
      background-color: unset;

      &.active {
        p {
          position: relative;
        }

        p::after {
          content: '';
          display: block;
          width: 100%;
          background: radial-gradient(circle, $primary 0%, $black 100%);
          height: 3px;
          box-shadow: rgb(230 31 38 / 20%) 0px -2px 7px 4px;
        }
      }

      &:hover {
        color: $red;
        border: 0;
        border-color: unset;
        background-color: unset;
      }
    }
  }
}
.image-profile {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  width: 250px;
  height: 250px;

  &-team {
    border-radius: 50%;
    width: 80px;
    height: 80px;

    @include media-breakpoint-down(md) {
      width: 50px;
      height: 50px;
    }
  }
}

.logo-club {
  .img-club {
    height: 250px;

    &.big {
      $size-big: 250px;
      width: $size-big;
      max-width: $size-big;
      height: $size-big;
      max-height: $size-big;
    }



    &.small {
      $size-small: 35px;
      width: $size-small;
      height: $size-small;
      max-width: $size-small;
      max-height: $size-small;
    }

    &.circle {
      border-radius: 50%
    }
  }
}

.list-img {
  margin-left: -37px;
  border: 2px solid $tertiary;

  &:first-child {
    margin-left: 0;
  }

  &.number {
    background-color: $primary;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.player-image {
  height: 38px;
  width: 38px;
  margin: auto;
  overflow: hidden;
  position: relative;
  border-radius: 50%;

  img {
    height: 38px;
    border-radius: 50%;
  }
}
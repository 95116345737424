$theme-text-colors: (
  "light"$light $dark,
);

$theme-line-colors: (
  "primary"$primary $dark,
);

@each $color-name,
$color-1,
$color-2 in $theme-text-colors {
  .text-gradient-#{$color-name} {
    background: radial-gradient(circle, rgba($color-1, 1) 50%, rgba($color-2, 1) 150%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline;
  }
}


.line-gradient-light {
  background: radial-gradient(circle, rgba($light, .5) 0%, rgba($dark, 1) 100%);
  height: 4px;
  width: 100%;
  margin: auto;
}

.line-gradient-primary {
  background: radial-gradient(circle, rgba($primary, .8) 0%, rgba($dark, 1) 100%);
  height: 4px;
  width: 100%;
  margin: auto;
}

@each $color-name,
$color-1,
$color-2 in $theme-line-colors {
  .line-gradient-left-#{$color-name} {
    background: linear-gradient(99deg, rgba($color-1, 1) 0%, rgba($color-2, 1) 100%);
    height: 4px;
    width: 150px;
  }
}
.border-logo-banner-game {
  $size-card: 60px;
  border: 1px solid rgba($light, .5);
  border-radius: 50%;
  width: $size-card;
  max-width: $size-card;
  height: $size-card;
  max-height: $size-card;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-banner-game {
    $size-logo: 35px;
    width: $size-logo;
    max-width: $size-logo;
    height: $size-logo;
    max-height: $size-logo;
  }
  
  &.active {
    background-color: $primary-gray;
    border-color: $red;
  }

  .icon {
    display: none;
  }
  
  &.con-tooltip {
    @include tooltip($size-card);
  }
}
.custom-modal {
  .modal-content {
    background-color: $black;

    .modal-header {
      border-bottom: 0;
    }

    .modal-body {
      padding: 0 35px 35px 35px;

      .title {
        text-align: center;
        margin-bottom: 25px;
      }
    }

    .modal-footer {
      border-top: 0;
    }
  }
}

.custom-modal-backdrop {
  background-color: $gray-800;
}

.login-modal {
  .content {
    .btn-login {
      @include media-breakpoint-up(sm) {
        padding-left: 80px;
        padding-right: 80px;
      }
    }

    .section-line {
      margin-bottom: 24px;

      .line-start {
        padding-bottom: 24px;
      }

      .line-end {
        padding-bottom: 24px;
      }
    }
  }
}

.modal-detail-content {
  .modal-header {
    padding: 12px;
    border-bottom: 0;
    background: $black;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;

    @include media-breakpoint-down(md) {
      font-size: .7rem;
    }
  }

  .modal-body {
    padding: 0 !important;
  }
}
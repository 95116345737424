.event {
  padding-bottom: 6rem;

  @include media-breakpoint-down(md) {
    padding-bottom: 1rem;
  }

  .title-event {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    margin-top: 0rem;

    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
  }

  .fs-h1 {
    font-size: 4rem;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }

  }

  .fs-h4 {
    font-size: 2rem;
    letter-spacing: 2px;

    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }

  .text-spacing {
    letter-spacing: 4px;
  }

  li::marker {
    color: $primary;
  }

  .poster {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @include media-breakpoint-down(md) {
      margin-top: 10px;
    }
  }

  .box-gradient {
    .line-gradient-primary {
      &.left {
        transform: rotate(344deg);
        max-width: 300px;
        top: 3rem;
        left: 12rem;
      }

      &.right {
        transform: rotate(15deg);
        max-width: 300px;
        top: 3rem;
        right: 12rem;
      }
    }
  }

}
.news-card {
  cursor: pointer;

  &:hover {
    .topic {
      color: $primary;
      transition: color .15s ease-in-out;
    }

    .image {
      img {
        transform: scale(1.1);
      }
    }
  }

  .topic {
    transition: color .15s ease-in-out;
  }

  .image {
    position: relative;
    overflow: hidden;

    img {
      transition: all .15s;
      background-size: cover;
      background-position: center;
    }
  }
}
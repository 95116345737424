.header-title {
  display: flex;
  justify-content: center;
  width: 100%;

  .content {
    width: fit-content;

    .title {
      margin-left: 25px;
      margin-right: 25px;
    }

    .line {
      background-color: $primary;
      height: 4px;
      width: 100%;
    }
  }
}
.icon {
  &-border {
    display: flex;
    width: 50px;
    height: 50px;
    border: 1px solid rgba($white, .2);
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    &.youtube {
      &.can-hover:hover {
        background-color: $color-youtube;
        border: 0;
      }
    }

    &.facebook {
      &.can-hover:hover {
        background-color: $color-facebook;
        border: 0;
      }
    }

    &.instagram {
      &.can-hover:hover {
        background: $color-instagram;
        border: 0;
      }
    }

    &.tiktok {
      &.can-hover:hover {
        background: $color-tiktok;
        border: 0;
      }
    }
  }
}
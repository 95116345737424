@font-face {
  font-family: Kanit;
  src: url(../assets/font/Kanit-Regular.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

:root {
  --bs-body-font-size: 20px;
}

body {
  font-family: Kanit, sans-serif;
  background-color: $dark;
  color: $white;
}

.cursor-pointer {
  cursor: pointer;
}

.circle-bg {
  position: absolute;
  background-color: $primary;
  border-radius: 50%;
  filter: blur(180px);
  z-index: -1;
  width: 40rem;
  height: 40rem;
  opacity: 0.3;
  bottom: 0;
  left: -20rem;
}

#root {
  overflow-x: hidden;
}

.custom-navbar {
  ~.container-xl {
    min-height: 100vh;
    padding-bottom: 5rem;
  }
}

@for $i from 1 through 400 {
  .width-#{$i} {
    width: $i * 1px;
  }
}

@for $i from 1 through 400 {
  .height-#{$i} {
    height: $i * 1px;
  }
}

@for $i from 1 through 30 {
  .f-size-#{$i} {
    font-size: $i * 1px;
  }
}

.img-register-tournament {
  height: 60px;
  width: 60px;
}
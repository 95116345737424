.table {
  thead {
    th {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  tbody {
    tr {
      td {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      &:first-child:nth-last-child(1) {
        td:first-child {
          border-radius: .75rem 0 0 .75rem;
        }

        td:last-child {
          border-radius: 0 .75rem .75rem 0;
        }
      }

      &:first-child {
        td:first-child {
          border-radius: .75rem 0 0 0;
        }

        td:last-child {
          border-radius: 0 .75rem 0 0;
        }
      }

      &:last-child {
        td:first-child {
          border-radius: 0 0 0 .75rem;
        }

        td:last-child {
          border-radius: 0 0 .75rem 0;
        }
      }
    }
  }
}

.head-table {
  background: radial-gradient(circle, rgba($primary, .9) -20%, rgba($dark, 1) 100%);
  text-align: center;
  color: $white;
  padding: .3rem 0;
}

.table-game {
  white-space: nowrap;

  &.table> :not(caption)>*>* {
    border: 0;
  }

  border-collapse: collapse;
  border-radius: 1.2rem;

  &.hover {
    tbody {
      tr {
        &:hover {
          td {
            opacity: .6;
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 5px solid $black;

      td:first-child {
        border-top-left-radius: 1.2rem;
        background: $tertiary;
        background: linear-gradient(93deg, rgba($tertiary, 0) 0%, rgba($tertiary, 1) 100%);
      }

      td:last-child {
        border-top-right-radius: 1.2rem;
        background: $tertiary;
        background: linear-gradient(266deg, rgba($tertiary, -15%) 0%, rgba($tertiary, 1) 100%);
      }
    }

    tr td {
      vertical-align: middle;
      background-color: $tertiary;
      padding: .3rem 0;
      white-space: nowrap;
    }
  }
}

.td-title {
  font-size: 1.8rem;
  background-color: $tertiary;
  border: 1px solid rgba($primary, .5);
  display: flex;
  align-content: center;
  width: 100%;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .col-1 {
    white-space: normal;
    overflow-x: scroll;
    width: 40%;
    padding-left: 2rem;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 1rem;
      text-align: center;
    }

    .no-row {
      color: $primary;
    }
  }

  .col-2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;

    @include media-breakpoint-down(md) {
      display: block;
      width: auto;
    }
  }

  .col-3 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;

    @include media-breakpoint-down(md) {
      display: block;
      width: auto;
    }
  }

  .col-4 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
    padding-right: 2rem;

    @include media-breakpoint-down(md) {
      display: block;
      width: auto;
      padding-right: 1rem;
    }
  }
}

.table-game-winner {
  min-width: 500px;
  overflow-x: scroll;
}
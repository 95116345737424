.form-control {
  &.is-invalid {
    padding-right: .75rem;
  }

  &:disabled {
    background-color: black;
  }
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: $primary;
}
.card-detail {
  --size-border: 8px;
  width: fit-content;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0;
  border: 0;

  .card-img-top {
    width: 100%;
    height: 225px;
    border-radius: 0;
    object-fit: cover;
  }

  .card-body {
    color: $white;
    background: $black;
    background: linear-gradient(0deg, $black 0%, rgba($black, .9) 49%, rgba($black, 0) 100%);
    padding-top: 4rem;
    bottom: 0;
  }

  &.non-height {
    .card-body {
      height: 105px;
    }
  }

  &.fix-height {
    .card-body {
      height: 160px;

      @include media-breakpoint-down(md) {
        height: unset;
      }
    }
  }
}
.stepper-wrapper {
  --color: #561b1d;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    gap: 10px;
  }
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex: 1;

  @include media-breakpoint-down(md) {
    flex-direction: row;
  }

  .step-name {
    margin-top: 8px;
    color: var(--color);
    font-size: 20px;
  }

  .step-date {
    margin-top: 8px;
    font-size: 20px;
    color: $gray-600;
  }
}

.stepper-item::before {
  content: "";
  position: absolute;
  border-bottom: 8px solid var(--color);
  width: 100%;
  top: 10px;
  right: -50%;
  z-index: 1;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.stepper-item .step-counter {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 5px solid var(--color);
  border-radius: 50%;
  background: var(--color);
  margin-bottom: 6px;
}

.stepper-item.active {
  .step-name {
    color: $primary;
  }

  .step-date {
    color: $white;
  }

  // &::before {
  //   border-bottom: 8px solid $primary;
  // }

  .step-counter {
    background: $primary;
    border: 5px solid $primary;
    background: $primary;

    &::after {
      content: '';
      opacity: 1;
      position: absolute;
      border: 5px solid $primary;
      animation: animateLoader 2s ease-out infinite;
      animation-delay: 0.8s;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      z-index: 2;
    }
  }
}

.stepper-item:last-child:before {
  content: none;
}

@keyframes animateLoader {
  0% {
    transform: scale(1);
  }

  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}
.custom-footer {
  background: $primary;
  background: linear-gradient(180deg, rgba($primary, 0) 30%, $primary 200%);
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;

  .container {
    position: absolute;
    left: 50%;
    bottom: 55%;
    transform: translate(-50%, 50%);
    z-index: 3;
    padding-bottom: 2rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  .conditions {
    font-size: 12px;
  }
}

.footer {
  position: fixed;
  align-items: center;
  height: 75px;
  max-height: 55px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: $secondary-bg;
  background: linear-gradient(360deg, rgba($secondary-bg, 1) 40%, rgba($black, 1) 100%);
  z-index: 10;

  .menu {
    display: flex;
    gap: 20px;
    white-space: nowrap;
    justify-content: space-between;
    align-content: center;

    // @include media-breakpoint-down(lg) {
    //   overflow-x: scroll;
    // }

    .logo {
      display: block;
      position: relative;
      width: 100px;

      .icon {
        position: absolute;
        left: 0;
        bottom: 14px;
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      @include media-breakpoint-down(md) {
        width: 70px;
        max-width: 70px;
      }
    }

    a {
      display: block;
      position: relative;
      width: 100px;

      svg {
        width: 35px;
        height: 35px;

        @include media-breakpoint-down(md) {
          width: 27px;
          height: 27px;
        }
      }
    }
  }

  .isActive {
    border-bottom: 2px solid $primary;

    @include media-breakpoint-down(md) {
      border-bottom: 0;
    }

    p {
      fill: $primary;
    }
  }

  p {
    color: $white;
  }
}


.animation-water {
  width: 100%;
  height: 220px;
  left: 0;
  bottom: -10px;
  position: absolute;
  overflow: hidden;
  opacity: .5;
  z-index: 1;
}

.water {
  position: absolute;
}

.water .bubbles {
  position: absolute;
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: 0;
  left: 0;
  opacity: .5;
  animation: moves 2s linear infinite;
}

.water .bubbles:nth-child(1) {
  background-color: rgb(123, 61, 61);
  opacity: 0.6;
}

.water .bubbles:nth-child(3) {
  background-color: rgb(164, 36, 36);
  opacity: 0.3;
}

.water .bubbles:nth-child(4) {
  background-color: rgb(233, 94, 94);
  opacity: 0.6;
}

.water .bubbles:nth-child(5) {
  background-color: rgb(175, 80, 80);
  opacity: 0.8;
}

@keyframes moves {
  0% {
    transform: translate(0, 0);
    opacity: .5;
  }

  50% {
    transform: translate(0, -90px);
    opacity: .8;
  }

  100% {
    transform: translate(0, -150px);
    opacity: 0;
  }
}
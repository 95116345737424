@mixin tooltip($margin-top) {
  position: relative;
  transition: all .3s ease-in-out;

  &:hover {
    .tooltip {
      display: block;
      opacity: 1;
      transition: .3s linear;
      animation: odsoky 1s ease-in-out infinite alternate;
    }
  }

  .tooltip {
    display: none;
    z-index: 1;
    opacity: .40;
    text-align: center;
    background: $tertiary;
    color: $white;
    position: absolute;
    top: calc(($margin-top + 5px)*(-1));
    border-radius: 9px;
    box-shadow: 0 0 3px rgba($white, 0.86);
    border: 1px solid $white;
    padding: .8rem 1.5rem;
  }
}

@keyframes odsoky {
  0% {
    transform: translateY(6px);
  }

  100% {
    transform: translateY(1px);
  }
}
.game-card {
  $size-card: 125px;
  background-color: $secondary-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  height: $size-card;
  min-height: $size-card;

  &.con-tooltip {
    @include tooltip($size-card - 10px);
  }
}
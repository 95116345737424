.content-card {
  cursor: pointer;

  &:hover {
    .topic {
      color: $primary;
      transition: color .15s ease-in-out;
    }

    .image {
      img {
        transform: scale(1.1);
      }
    }
  }

  .topic {
    transition: color .15s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .image {
    position: relative;
    overflow: hidden;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      transition: all .15s;
      background-size: cover;
      background-position: center;
      max-height: 175px;
    }

    .status {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: .3rem .5rem .3rem .5rem;
      background-color: rgba($dark, .5);
      font-size: .8rem;

      &::before {
        content: '\A';
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        background: $green;
        display: inline-block;
        margin-right: .3rem;
      }
    }

    .status-fix {
      position: absolute;
      top: 5px;
      right: 18px;
      font-size: .8rem;

      .badge-status {
        padding: 3px 24px;
        display: flex;
        align-items: center;
        background-color: rgb(19 18 17 / 67%) !important;
      }
    }
  }

  &.main {
    .image {
      height: 350px;

      img {
        max-height: 350px;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .text-date {
    font-size: 16px;
  }
}

.contact-description {
  min-height: 537px;
}

.content-streaming {
  min-height: 600px;
}
.swiper-pagination-bullet-active {
  background-color: $primary;
  width: 12px;
  height: 12px;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.banner-swiper{
  .swiper-slide {
    height: 493px;
  }
}
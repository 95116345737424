.user-information {
  .profile {
    .icon {
      height: 250px;
      width: 250px;
      border: 4px solid $white;
      border-radius: 50%;

      svg {
        height: 125px;
        width: 125px;
      }
    }
  }

  .club {
    img {
      width: 35px;
    }
  }
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .follower {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.game {
  .card {
    p {
      font-size: 12px;
    }
  }
}
.club-detail-modal {
  .social-media-no {
    position: relative;
    top: 35px;
  }
}

.club-detail {
  .club-image {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .club-name {
    display: flex;
    justify-content: center;
    width: 100%;

    .content {
      width: fit-content;

      .name {
        margin-left: 75px;
        margin-right: 75px;
      }

      .line {
        background-color: $primary;
        height: 4px;
        width: 100%;
      }
    }
  }

  .club-detail {
    text-align: center;
  }

  .player-competition {
    .nav.nav-pills {
      .nav-item {
        width: 50%;

        @include media-breakpoint-up(md) {
          width: 25%;
        }
      }
    }

    .player {
      .criteria {
        button {
          height: 38px;
          position: relative;
          top: 32px;
        }
      }
    }

    .competition {
      .game-list {
        .item {
          border: 2px solid rgba($border-color, .5);
          width: 100%;
          color: $white;
          padding-top: 15px;
          padding-bottom: 15px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          transition: border 0.15s ease-in-out;

          &:hover {
            border: 2px solid $primary;
            transition: border 0.15s ease-in-out;
          }
        }

        .img {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
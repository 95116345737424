// see more: https://getbootstrap.com/docs/5.2/components/buttons/

@mixin button-outline-variant($color,
  $hover-color: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-border-color: #{$color};

  @if $color ==$white {
    --#{$prefix}btn-hover-color: #{$dark};
  }

  @else {
    --#{$prefix}btn-hover-color: #{$hover-color};
  }

  --#{$prefix}btn-hover-bg: #{$color};
  --#{$prefix}btn-hover-border-color: #{$active-border};

  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($color)};

  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-background};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};

  --#{$prefix}btn-disabled-color: #{$color};
  --#{$prefix}btn-disabled-bg: transparent;
  --#{$prefix}btn-disabled-border-color: #{$color};
  --#{$prefix}gradient: none;
}

@each $color,
$value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value, // color
      $secondary, // color-hover
      shade-color($value, $btn-active-bg-shade-amount)); // active-background
    // active-border
    // active-color
  }
}

.btn-link {
  @include button-variant(transparent, // background
    transparent, // border
    $secondary, // color
    transparent, // hover-background
    transparent, // hover-border
    $primary, // hover-color
    transparent, // active-background
    transparent, // active-border
    shade-color($primary, $btn-active-bg-shade-amount)); // active-color
  // disabled-background
  // disabled-border
  // disabled-color

  text-decoration: none;
}

.btn-input {
  @include button-variant($input-bg, // background
    $input-border-color, // border
    $secondary, // color
    $input-bg, // hover-background
    $input-border-color, // hover-border
    $primary, // hover-color
    $input-bg, // active-background
    $input-border-color, // active-border
    shade-color($primary, $btn-active-bg-shade-amount)); // active-color
  // disabled-background
  // disabled-border
  // disabled-color

  border-left: 0;
  margin-left: 0 !important;
}
.social-media {
  .item {
    color: $white;
    text-decoration: none;

    .icon {
      display: flex;
      width: 50px;
      height: 50px;
      border: 2px solid rgba($white, .2);
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      font-size: 22px;

      &.youtube:hover {
        background-color: $color-youtube;
        border: 0;
      }

      &.facebook:hover {
        background-color: $color-facebook;
        border: 0;
      }

      &.instagram:hover {
        background: $color-instagram;
        border: 0;
      }
    }
  }
}
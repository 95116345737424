$theme-colors: (
  "danger"$danger,
  "primary"$primary,
  "white"$white,
  "success"$success,
);

@each $color-name,
$color in $theme-colors {
  .bg-overlay-#{$color-name} {
    background-color: rgba($color, .2);
    color: $color;
    border-radius: 50rem;
    padding: .1rem 1rem;
  }
}
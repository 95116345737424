.alert-terms {
  background-color: $dark;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 9999999;
  bottom: 0;
  padding: 1.3rem;

  a {
    color: $light;
    border-bottom: 1px solid;
  }

  .btn {
    @include media-breakpoint-down(md) {
      margin-top: 1rem;
      width: 100%;
    }
  }
}